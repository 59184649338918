import { ReactElement, useState } from 'react';
import Paper from '@material-ui/core/Paper';

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import CreateIcon from '@mui/icons-material/Create';
import { format } from 'date-fns';
import { Checkbox } from '@mui/material';
import GraphCard from '~/ui/components/reusable/GraphCard';

import { IPatientOverview } from '~/services/api/patients/types';
import styles from './Readings.module.scss';
import { DeviceType } from '~/services/api/enums';
import {
  IBloodGlucoseReading,
  IBloodPressureReading,
  IPulseOximeterReading,
  IReading,
  IWeightScaleReading,
} from '~/services/api/readings/types';
import BloodPressure from '~/ui/components/reusable/Reading/BloodPressure';
import WeightScale from '~/ui/components/reusable/Reading/WeightScale';
import PulseOximeter from '~/ui/components/reusable/Reading/PulseOximeter';
import BloodGlucose from '~/ui/components/reusable/Reading/BloodGlucose';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { UserPermission, UserRole } from '~/types';
import Button from '~/ui/components/common/Button';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import { extractErrorMessage } from '~/utils/error';

interface IProps {
  patientOverview: IPatientOverview;
  deviceTypeId: DeviceType;
  onReload: () => Promise<void>;
}

const Readings = ({ patientOverview, deviceTypeId, onReload }: IProps): ReactElement => {
  const [isEdit, setIsEdit] = useState(false);
  const [selectedReadingIds, setSelectedReadingIds] = useState<number[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const currentUser = useStoreState(state => state.auth.currentUser);

  const onDeleteReadings = useStoreActions(actions => actions.readings.onDeleteReadings);
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const canEdit =
    currentUser.roleId !== UserRole.HealthManager ||
    currentUser.permissions.includes(UserPermission.ManagePatients);

  const groupedReadings = patientOverview.readings.reduce(
    (readings, x) => ({
      ...readings,
      [format(new Date(x.measuredAt), 'dd MMM y')]: [
        ...(readings[format(new Date(x.measuredAt), 'dd MMM y')] ?? []),
        x,
      ],
    }),
    {} as { [key: string]: IReading[] },
  );

  const getReading = (reading: IReading) => {
    switch (deviceTypeId) {
      case DeviceType.BloodPressure:
        return (
          <BloodPressure
            reading={reading as IBloodPressureReading}
            rules={patientOverview.notificationRules}
            timezone={patientOverview.timezone}
            isHighlighted={selectedReadingIds.includes(reading.id)}
          />
        );
      case DeviceType.WeightScale:
        return (
          <WeightScale
            reading={reading as IWeightScaleReading}
            rules={patientOverview.notificationRules}
            timezone={patientOverview.timezone}
            isHighlighted={selectedReadingIds.includes(reading.id)}
          />
        );
      case DeviceType.PulseOximeter:
        return (
          <PulseOximeter
            reading={reading as IPulseOximeterReading}
            rules={patientOverview.notificationRules}
            timezone={patientOverview.timezone}
            isHighlighted={selectedReadingIds.includes(reading.id)}
          />
        );
      case DeviceType.BloodGlucose:
        return (
          <BloodGlucose
            reading={reading as IBloodGlucoseReading}
            rules={patientOverview.notificationRules}
            timezone={patientOverview.timezone}
            isHighlighted={selectedReadingIds.includes(reading.id)}
          />
        );
      default:
        throw new Error('Invalid DeviceType.');
    }
  };

  const onEdit = () => setIsEdit(true);

  const onSelect = (checked: boolean, id: number) =>
    setSelectedReadingIds(value => (checked ? [...value, id] : value.filter(x => x !== id)));

  const onSelectAll = () => setSelectedReadingIds(patientOverview.readings.map(x => x.id));

  const onUnselectAll = () => setSelectedReadingIds([]);

  const onCancel = () => {
    setIsEdit(false);
    setSelectedReadingIds([]);
  };

  const onDelete = () => setShowConfirmModal(true);

  const handleDelete = async () => {
    try {
      await onDeleteReadings(selectedReadingIds);
      await onReload();

      setIsEdit(false);
      setSelectedReadingIds([]);
      setShowConfirmModal(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  return (
    <Paper elevation={24} square className={styles.container}>
      {patientOverview.readings.length ? (
        <TableContainer>
          <Table className={styles.table}>
            <TableHead>
              <TableRow>
                <TableCell width={160}>Date</TableCell>
                <TableCell>Readings</TableCell>
                <TableCell align="right">
                  {canEdit && !isEdit && (
                    <Button className={styles.editButton} onClick={onEdit}>
                      <CreateIcon /> &nbsp;Edit
                    </Button>
                  )}
                  {isEdit && (
                    <>
                      {selectedReadingIds.length > 0 && (
                        <Button className={styles.editButton} onClick={onUnselectAll}>
                          Unselect All
                        </Button>
                      )}
                      {selectedReadingIds.length !== patientOverview.readings.length && (
                        <Button className={styles.editButton} onClick={onSelectAll}>
                          Select All
                        </Button>
                      )}
                      <Button
                        className={styles.deleteButton}
                        onClick={onDelete}
                        disabled={selectedReadingIds.length === 0}
                      >
                        Delete ({selectedReadingIds.length})
                      </Button>
                      <Button className={styles.editButton} onClick={onCancel}>
                        Cancel
                      </Button>
                    </>
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(groupedReadings).map(([date, readings]) => (
                <TableRow key={date}>
                  <TableCell>{date}</TableCell>
                  <TableCell colSpan={2}>
                    <Grid container spacing={2} wrap="nowrap" className={styles.readingsContainer}>
                      {readings.map(reading => (
                        <Grid item key={reading.id}>
                          <Grid container spacing={1} wrap="nowrap" alignItems="center">
                            {isEdit && (
                              <Grid item>
                                <Checkbox
                                  checked={selectedReadingIds.includes(reading.id)}
                                  onChange={(_e, checked) => onSelect(checked, reading.id)}
                                />
                              </Grid>
                            )}
                            <Grid item>{getReading(reading)}</Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <GraphCard title="Readings" showNoData />
      )}
      {showConfirmModal && (
        <ConfirmModal
          title="Delete"
          description="Are you sure you want to delete the selected readings?"
          onClose={() => setShowConfirmModal(false)}
          onConfirm={handleDelete}
        />
      )}
    </Paper>
  );
};

export default Readings;
