import { IPhysician } from '~/services/api/physicians/types';
import { PhysicianType } from '~/services/api/enums';

const getPhysicianInitialValues = (physician: IPhysician): IPhysician => ({
  ...physician,
  id: physician.id ?? 0,
  name: physician.name ?? '',
  phone: physician.phone ?? '',
  fax: physician.fax ?? '',
  specialty: physician.specialty ?? '',
  typeId: physician.typeId ?? PhysicianType.Secondary,
});

export default getPhysicianInitialValues;
