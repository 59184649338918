import { Day, DayPart } from '~/types';
import { IDayPartReading, daysList } from '../../types';

const getGroupedData = (dayPartReadings: IDayPartReading[], dayPart: DayPart, day: Day): number =>
  dayPartReadings.filter(x => x.dayPart === dayPart && x.day === day).length;

const getData = (dayPartReadings: IDayPartReading[]) => ({
  morning: daysList.map(day => getGroupedData(dayPartReadings, DayPart.Morning, day)),
  noon: daysList.map(day => getGroupedData(dayPartReadings, DayPart.Noon, day)),
  evening: daysList.map(day => getGroupedData(dayPartReadings, DayPart.Evening, day)),
  night: daysList.map(day => getGroupedData(dayPartReadings, DayPart.Night, day)),
});

export const getChartOptions = (dayPartReadings: IDayPartReading[]): Highcharts.Options => {
  const data = getData(dayPartReadings);

  return {
    chart: {
      type: 'column',
      marginTop: 25,
      height: 476,
    },
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      title: {
        text: 'Days',
      },
      categories: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      gridLineWidth: 1,
    },
    yAxis: {
      title: {
        text: 'No of Readings',
      },
      gridLineWidth: 0,
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
        },
      },
    },
    series: [
      {
        type: 'column',
        name: 'Morning',
        data: data.morning,
        color: '#dc8834',
      },
      {
        type: 'column',
        name: 'Noon',
        data: data.noon,
        color: '#26af27 ',
      },
      {
        type: 'column',
        name: 'Evening',
        data: data.evening,
        color: '#1d40aa',
      },
      {
        type: 'column',
        name: 'Night',
        data: data.night,
        color: '#191919',
      },
    ],
  };
};

export default {};
