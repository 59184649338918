import { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import api from '~/services/api';
import { useStoreState, useStoreActions } from '~/store/hooks';
import downloadFile from '~/utils/downloadFile';
import { getFileNameFromContentDisposition } from '~/utils/headers';
import { extractErrorMessage } from '~/utils/error';
import Print from './components/Print';
import Icd10Codes from './components/Icd10Codes';
import Medications from './components/Medications';
import Hospitalizations from './components/Hospitalizations';
import Goals from './components/Goals';
import Loader from '~/ui/components/common/Loader';

import { UserRole, UserPermission } from '~/types';
import { IPagePermissions } from './types';
import styles from './PlanOfCare.module.scss';
import Interventions from './components/Interventions';

type PatientParams = {
  id: string;
};

const PlanOfCare = (): ReactElement => {
  const routerParams = useParams<PatientParams>();
  const patientId = Number(routerParams.id);

  const [loading, setLoading] = useState(true);
  const [isGettingReport, setIsGettingReport] = useState(false);

  const userRole = useStoreState(state => state.auth.currentUser?.roleId);
  const userPermissions = useStoreState(state => state.auth.currentUser?.permissions || []);
  const patientInfo = useStoreState(state => state.patient.info);
  const carePlanIcd10Codes = useStoreState(state => state.carePlanIcd10Codes.items);
  const carePlanMedications = useStoreState(state => state.carePlanMedications.items);
  const carePlanHospitalizations = useStoreState(state => state.carePlanHospitalizations.items);
  const carePlanGoals = useStoreState(state => state.carePlanGoals.items);
  const carePlanInterventions = useStoreState(state => state.carePlanInterventions.items);

  const { onGetCarePlanIcd10Codes } = useStoreActions(actions => actions.carePlanIcd10Codes);
  const { onGetCarePlanMedications } = useStoreActions(actions => actions.carePlanMedications);
  const { onGetCarePlanHospitalizations } = useStoreActions(
    actions => actions.carePlanHospitalizations,
  );
  const { onGetCarePlanGoals } = useStoreActions(actions => actions.carePlanGoals);
  const { onGetCarePlanInterventions } = useStoreActions(actions => actions.carePlanInterventions);
  const { showError } = useStoreActions(actions => actions.snackbar);

  const permissions: IPagePermissions = {
    canManage:
      userRole !== UserRole.HealthManager ||
      userPermissions.includes(UserPermission.ManagePatients),
  };

  const handleGetReport = async () => {
    try {
      setIsGettingReport(true);
      const { data, headers } = await api.patients.getCarePlanReport(patientId);
      const fileName = getFileNameFromContentDisposition(headers['content-disposition']);
      downloadFile(data, fileName);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsGettingReport(false);
    }
  };

  const onMount = async () => {
    try {
      await Promise.all([
        onGetCarePlanIcd10Codes(patientId),
        onGetCarePlanMedications(patientId),
        onGetCarePlanHospitalizations(patientId),
        onGetCarePlanGoals(patientId),
        onGetCarePlanInterventions(patientId),
      ]);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Print isLoading={isGettingReport} onPrint={() => handleGetReport()} />
          <Icd10Codes patientId={patientId} items={carePlanIcd10Codes} permissions={permissions} />
          <div className={styles.heightSpacer} />
          <Medications
            patientId={patientId}
            items={carePlanMedications}
            permissions={permissions}
          />
          <div className={styles.heightSpacer} />
          <Hospitalizations
            patientId={patientId}
            items={carePlanHospitalizations}
            permissions={permissions}
            isPatientSmoker={patientInfo.isSmoker}
          />
          <div className={styles.heightSpacer} />
          <Goals patientId={patientId} items={carePlanGoals} permissions={permissions} />
          <div className={styles.heightSpacer} />
          <Interventions
            patientId={patientId}
            items={carePlanInterventions}
            permissions={permissions}
          />
        </>
      )}
    </div>
  );
};

export default PlanOfCare;
