import { useRef, ReactElement } from 'react';
import { Controller, Control, FieldErrors } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { parseISO, formatISO, isValid } from 'date-fns';
import get from 'lodash/get';
import IconButton from '@material-ui/core/IconButton';
import EventIcon from '@mui/icons-material/Event';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import styles from './DatePicker.module.scss';

interface IProps {
  control: Control<any>;
  errors: FieldErrors;
  name: string;
  minDate?: Date;
  maxDate?: Date;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  showTimeSelect?: boolean;
  dateFormat?: string;
  isDateOnly?: boolean;
}

const DatePickerComponent = ({
  control,
  errors,
  name,
  minDate,
  maxDate,
  label = '',
  placeholder = '',
  disabled = false,
  showTimeSelect = false,
  dateFormat = 'MM/dd/yyyy',
  isDateOnly = false,
}: IProps): ReactElement => {
  const startRef = useRef<DatePicker>();

  const error = get(errors, `${name}.message`, '');

  const handleChange = (date: [Date, Date] | Date) => {
    if (Array.isArray(date) || !isValid(date)) {
      return isDateOnly ? null : '';
    }

    return formatISO(date, { representation: isDateOnly ? 'date' : 'complete' });
  };

  const formatValue = (val: any) => {
    const date = parseISO(val);

    if (!isValid(date)) return undefined;
    return date;
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <FormControl component="fieldset" className={styles.datePickerControl}>
          <FormLabel component="legend" className={styles.label}>
            {label}
          </FormLabel>
          <DatePicker
            ref={startRef}
            className={styles.datePicker}
            showTimeSelect={showTimeSelect}
            timeFormat="HH:mm"
            timeIntervals={1}
            timeCaption="Time"
            dateFormat={dateFormat}
            selected={formatValue(value)}
            onChange={val => onChange(handleChange(val))}
            minDate={minDate}
            maxDate={maxDate}
            placeholderText={placeholder}
            disabled={disabled}
          />
          <IconButton className={styles.icon} onClick={() => startRef.current.setOpen(true)}>
            <EventIcon />
          </IconButton>
          {error && <FormHelperText error>{error}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

export default DatePickerComponent;
