import axios from 'axios';
import { IResponse } from '~/services/api/types';
import { IPatientDeviceHistoryLog } from './types';

const apiUrl = 'device-history-logs';

export const getByPatientId = (patientId: number): IResponse<IPatientDeviceHistoryLog[]> =>
  axios({
    method: 'get',
    url: `${apiUrl}/by-patient-id/${patientId}`,
  });

export default {};
