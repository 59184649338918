import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetNoteTemplatesByTypeId } from '../types';

const onGetNoteTemplatesByTypeId: IThunkGetNoteTemplatesByTypeId = thunk(
  // @ts-ignore
  async ({ setNoteTemplates, reset }, typeId) => {
    reset();

    const { data } = await api.noteTemplates.getByTypeId(typeId);

    setNoteTemplates({ typeId, items: data });
  },
);

export default onGetNoteTemplatesByTypeId;
