import { useState, ReactElement } from 'react';
import { Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import Modal from '~/ui/components/common/Modal';
import { NoteTemplateType } from '~/services/api/enums';
import Button from '~/ui/components/common/Button';
import { IFormValues } from './types';
import initialValues from './form/initialValues';
import validate from './form/validate';
import Input from '~/ui/components/inputs/Input';

interface IProps {
  typeId: NoteTemplateType;
  content: string;
  onClose: () => void;
}

const CreateNoteTemplateModal = ({ typeId, content, onClose }: IProps): ReactElement => {
  const [isCreating, setIsCreating] = useState(false);

  const { showError } = useStoreActions(actions => actions.snackbar);
  const { onCreateNoteTemplateByTypeId } = useStoreActions(actions => actions.noteTemplates);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormValues>({
    resolver: validate,
    defaultValues: initialValues,
  });

  const onSubmit = async (values: IFormValues) => {
    try {
      setIsCreating(true);
      await onCreateNoteTemplateByTypeId({ typeId, item: { name: values.name, content } });
      onClose();
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsCreating(false);
    }
  };

  return (
    <Modal open closeOnlyByCross title="Save Template" maxWidth="sm" onClose={onClose}>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Input name="name" register={register} errors={errors} label="Name *" />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Button
                size="large"
                variant="outlined"
                color="primary"
                disabled={isCreating}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                size="large"
                variant="contained"
                color="primary"
                isLoading={isCreating}
                disabled={isCreating}
                onClick={handleSubmit(onSubmit)}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Modal>
  );
};

export default CreateNoteTemplateModal;
