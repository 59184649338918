import reset from './reset';
import setNoteTemplates from './setNoteTemplates';
import addNoteTemplate from './addNoteTemplate';
import removeNoteTemplate from './removeNoteTemplate';

export default {
  setNoteTemplates,
  reset,
  addNoteTemplate,
  removeNoteTemplate,
};
