import { ReactElement } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { getChartOptions } from './helper';
import GraphCard from '~/ui/components/reusable/GraphCard';
import { IDayPartReading } from '../../types';

interface IProps {
  noData: boolean;
  dayPartReadings: IDayPartReading[];
}

const DaysReadingCountCount = ({ noData, dayPartReadings }: IProps): ReactElement => {
  const title = 'Days Reading Count';

  if (noData) {
    return <GraphCard title={title} showNoData />;
  }

  const options = getChartOptions(dayPartReadings);

  return (
    <GraphCard title={title}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </GraphCard>
  );
};

export default DaysReadingCountCount;
