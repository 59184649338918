import setCarePlanInterventions from './setCarePlanInterventions';
import addCarePlanIntervention from './addCarePlanIntervention';
import updateCarePlanIntervention from './updateCarePlanIntervention';
import removeCarePlanIntervention from './removeCarePlanIntervention';

export default {
  setCarePlanInterventions,
  addCarePlanIntervention,
  updateCarePlanIntervention,
  removeCarePlanIntervention,
};
