import { IFormValues } from '~/ui/components/reusable/Notes/NoteForm/types';

const initialValues: IFormValues = {
  content: '',
  isTelephoneConversation: false,
  showReminder: false,
  reminderDate: '',
  notifyHealthManager: false,
};

export default initialValues;
