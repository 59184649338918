import onGetCarePlanInterventions from './onGetCarePlanInterventions';
import onCreateCarePlanIntervention from './onCreateCarePlanIntervention';
import onUpdateCarePlanIntervention from './onUpdateCarePlanIntervention';
import onRemoveCarePlanIntervention from './onRemoveCarePlanIntervention';

export default {
  onGetCarePlanInterventions,
  onCreateCarePlanIntervention,
  onUpdateCarePlanIntervention,
  onRemoveCarePlanIntervention,
};
