import { ICarePlanIntervention } from '~/services/api/carePlanInterventions/types';
import { IFormValues } from '../../popups/InterventionModal/form/types';

const getEditValues = (intervention: ICarePlanIntervention): IFormValues => {
  const date = new Date(intervention.date);

  return {
    reason: intervention.reason,
    date: new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()).toISOString(),
  };
};

export default getEditValues;
