import { Gender } from '~/types';
import { IPatientFormValues } from '~/ui/pages/Patient/Dashboard/components/PersonalData/PatientProfile/types';

const initialValues: IPatientFormValues = {
  programId: null,
  healthCareManagerIds: [],
  subjectId: '',
  firstName: '',
  middleName: '',
  lastName: '',
  phoneNumber: '',
  dateOfBirth: '',
  ethnicity: '',
  isHomeHealthCare: '0',
  hospitalizationRiskScore: null,
  gender: Gender.Male,
  country: '',
  state: '',
  city: '',
  address: '',
  zip: '',
  comment: '',
  timezone: null,
  bloodPressureDeviceId: null,
  weightScaleDeviceId: null,
  pulseOximeterDeviceId: null,
  bloodGlucoseDeviceId: null,
  homePhone: null,
  cellPhone: null,
  workPhone: null,
  shippingAddressSameAsHome: '',
  shippingAddress: '',
  shippingAddressCity: '',
  shippingAddressCountry: '',
  shippingAddressState: '',
  shippingAddressZip: null,
  emergencyContactFirstName: '',
  emergencyContactLastName: '',
  emergencyContactEmail: '',
  emergencyContactPhone: '',
  emergencyContactRelation: null,
  caretakers: [],
  homeHealthcarePeople: [],
  physicians: [],
};

export default initialValues;
