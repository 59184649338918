import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkUpdateTableCustomizationsByTypeId } from '../types';

const onUpdateTableCustomizationsByTypeId: IThunkUpdateTableCustomizationsByTypeId = thunk(
  // @ts-ignore
  async ({ setTableCustomizations }, { typeId, items }) => {
    const { data } = await api.tableCustomizations.updateByTypeId(typeId, items);

    setTableCustomizations({ typeId, items: data });
  },
);

export default onUpdateTableCustomizationsByTypeId;
