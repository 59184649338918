import initStore from './initStore';
import actions from './actions';
import thunks from './thunks';

import { INoteTemplatesModel } from './types';

const noteTemplates: INoteTemplatesModel = {
  ...initStore,
  ...actions,
  ...thunks,
};

export default noteTemplates;
