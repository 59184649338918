import { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@material-ui/core/Grid';
import Input from '~/ui/components/inputs/Input';
import PhoneFlagInput from '~/ui/components/inputs/PhoneFlag';

interface IProps {
  index: number;
  onRemove?: (index: number) => void;
}

const Physician = ({ index, onRemove }: IProps): ReactElement => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Input
                name={`physicians.${index}.name`}
                register={register}
                errors={errors}
                label="Name *"
              />
            </Grid>
            <Grid item xs={3}>
              <PhoneFlagInput
                name={`physicians.${index}.phone`}
                control={control}
                errors={errors}
                label="Phone *"
              />
            </Grid>
            <Grid item xs={3}>
              <PhoneFlagInput
                name={`physicians.${index}.fax`}
                control={control}
                errors={errors}
                label="Fax"
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                name={`physicians.${index}.specialty`}
                register={register}
                errors={errors}
                label="Specialty *"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {onRemove && (
            <IconButton title="Remove" onClick={() => onRemove(index)}>
              <DeleteIcon />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Physician;
