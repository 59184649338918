import axios from 'axios';
import { IResponse } from '~/services/api/types';
import { INoteTemplate } from './types';
import { NoteTemplateType } from '../enums';

const apiUrl = 'note-templates';

export const getByTypeId = (typeId: NoteTemplateType): IResponse<INoteTemplate[]> =>
  axios({
    method: 'get',
    url: `${apiUrl}/by-type-id/${typeId}`,
  });

export const createByTypeId = (
  typeId: NoteTemplateType,
  data: Omit<INoteTemplate, 'id'>,
): IResponse<INoteTemplate> =>
  axios({
    method: 'post',
    url: `${apiUrl}/by-type-id/${typeId}`,
    data,
  });

export const remove = (id: number): IResponse<void> =>
  axios({
    method: 'delete',
    url: `${apiUrl}/${id}`,
  });

export default {};
