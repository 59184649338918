import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetTableCustomizationsByTypeId } from '../types';

const onGetTableCustomizationsByTypeId: IThunkGetTableCustomizationsByTypeId = thunk(
  // @ts-ignore
  async ({ setTableCustomizations }, typeId) => {
    const { data } = await api.tableCustomizations.getByTypeId(typeId);

    setTableCustomizations({ typeId, items: data });
  },
);

export default onGetTableCustomizationsByTypeId;
