import { useState } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';

import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import { createValues } from './form';
import Button from '~/ui/components/common/Button';
import NoDataFound from '~/ui/components/reusable/NoDataFound';
import MedicationModal from '../popups/MedicationModal';
import Item from './Item';

import { ICarePlanMedication } from '~/services/api/carePlanMedications/types';
import { IFormValues } from '../popups/MedicationModal/form/types';
import { IPagePermissions } from '../../types';
import styles from './Medications.module.scss';

interface IProps {
  patientId: number;
  items: ICarePlanMedication[];
  permissions: IPagePermissions;
}

const Medications = ({ patientId, items, permissions }: IProps) => {
  const [showCreate, setShowCreate] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const { onCreateCarePlanMedication } = useStoreActions(actions => actions.carePlanMedications);

  const { showError } = useStoreActions(actions => actions.snackbar);

  const handleCreateMedication = async (values: IFormValues) => {
    try {
      setIsCreating(true);

      const payload = {
        ...values,
        startDate: values.startDate === '' ? null : values.startDate,
        endDate: values.endDate === '' ? null : values.endDate,
      };

      onCreateCarePlanMedication({ patientId, data: payload as ICarePlanMedication });
      setShowCreate(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        <div className={styles.title}>Medications</div>
        {permissions.canManage && (
          <Button variant="contained" color="primary" onClick={() => setShowCreate(true)}>
            + Add New
          </Button>
        )}
      </div>
      <div className={styles.tableContainer}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="20%">Name</TableCell>
                <TableCell width="10%">Start Date</TableCell>
                <TableCell width="10%">End Date</TableCell>
                <TableCell>Reason</TableCell>
                {permissions.canManage && <TableCell />}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.length ? (
                items.map(item => <Item key={item.id} item={item} permissions={permissions} />)
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>
                    <NoDataFound />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {showCreate && (
        <MedicationModal
          initialValues={createValues}
          isProcessing={isCreating}
          onSubmit={handleCreateMedication}
          onClose={() => setShowCreate(false)}
        />
      )}
    </div>
  );
};

export default Medications;
