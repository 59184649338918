import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isDateBiggerOrEqual } from '~/utils/date';

const validationSchema = object().shape({
  name: string().max(100, 'Max length - 100 symbols').required('Field is required'),
  reason: string().max(500, 'Max length - 500 symbols'),
  startDate: string().nullable(),
  endDate: string()
    .nullable()
    .test({
      name: 'dateCompare',
      message: '`End Date` should be equal or bigger then `Start Date`',
      test: (value, context) => {
        const { startDate } = context.parent;

        return !startDate || !value || isDateBiggerOrEqual(startDate, value);
      },
    }),
});

export default yupResolver(validationSchema);
