import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkRemoveNoteTemplate } from '../types';

// @ts-ignore
const onRemoveNoteTemplate: IThunkRemoveNoteTemplate = thunk(async ({ removeNoteTemplate }, id) => {
  await api.noteTemplates.remove(id);

  removeNoteTemplate(id);
});

export default onRemoveNoteTemplate;
