import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkCreateNoteTemplateByTypeId } from '../types';

const onCreateNoteTemplateByTypeId: IThunkCreateNoteTemplateByTypeId = thunk(
  // @ts-ignore
  async ({ addNoteTemplate }, { typeId, item }) => {
    const { data } = await api.noteTemplates.createByTypeId(typeId, item);

    addNoteTemplate({ typeId, item: data });
  },
);

export default onCreateNoteTemplateByTypeId;
