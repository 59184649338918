import { ReactElement } from 'react';

import { Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import { getMeasurementData } from '../helper';
import { isoToFormat } from '~/utils/date';

import { Timezone } from '~/types';
import { IWeightScaleReading } from '~/services/api/readings/types';

import styles from './WeightScale.module.scss';
import { INotificationRule } from '~/services/api/notificationRules/types';
import PreviousIcon from './Previous.svg';
import DailyIcon from './Daily.svg';
import WeeklyIcon from './Weekly.svg';
import { RuleCondition, RuleType } from '~/services/api/enums';
import Icon from './Icon';

interface IProps {
  reading: IWeightScaleReading;
  rules: INotificationRule[];
  timezone: Timezone;
  isHighlighted?: boolean;
}

const WeightScale = ({ reading, rules, timezone, isHighlighted }: IProps): ReactElement => {
  const previousGainData = getMeasurementData({
    typeId: RuleType.Weight,
    condition: RuleCondition.PreviousGain,
    value: reading.previousGainInLbs,
    rules,
  });

  const previousLossData = getMeasurementData({
    typeId: RuleType.Weight,
    condition: RuleCondition.PreviousLoss,
    value: reading.previousLossInLbs,
    rules,
  });

  const dailyGainData = getMeasurementData({
    typeId: RuleType.Weight,
    condition: RuleCondition.DailyGain,
    value: reading.dailyGainInLbs,
    rules,
  });

  const dailyLossData = getMeasurementData({
    typeId: RuleType.Weight,
    condition: RuleCondition.DailyLoss,
    value: reading.dailyLossInLbs,
    rules,
  });

  const weeklyGainData = getMeasurementData({
    typeId: RuleType.Weight,
    condition: RuleCondition.WeeklyGain,
    value: reading.weeklyGainInLbs,
    rules,
  });

  const weeklyLossData = getMeasurementData({
    typeId: RuleType.Weight,
    condition: RuleCondition.WeeklyLoss,
    value: reading.weeklyLossInLbs,
    rules,
  });

  const measurementDate = `${isoToFormat(reading.measuredAt, 'dd MMM y HH:mm')} ${timezone}`;

  const receivedBySystem = `Received by system: ${isoToFormat(
    reading.createdAt,
    'dd MMM y HH:mm',
  )} ${timezone}`;

  return (
    <div className={classNames(styles.container, { [styles.isHighlighted]: isHighlighted })}>
      <div className={styles.icon}>
        <Icon weight={reading.weightInLbs} />
      </div>
      <div className={styles.info}>
        <div className={styles.measurements}>
          <div className={styles.measurement}>
            <Tooltip title="Previous">
              <img src={PreviousIcon} alt="" />
            </Tooltip>
            {reading.previousGainInLbs > 0 && (
              <div className={classNames(styles.value, previousGainData.className)}>
                +{previousGainData.value}
              </div>
            )}
            {reading.previousLossInLbs !== null && (
              <div className={classNames(styles.value, previousLossData.className)}>
                {previousLossData.value}
              </div>
            )}
            {reading.previousGainInLbs === null && reading.previousLossInLbs === null && (
              <div className={styles.value}>NPR</div>
            )}
          </div>
          <div className={styles.measurement}>
            <Tooltip title="Daily">
              <img src={DailyIcon} alt="" />
            </Tooltip>
            {reading.dailyGainInLbs > 0 && (
              <div className={classNames(styles.value, dailyGainData.className)}>
                +{dailyGainData.value}
              </div>
            )}
            {reading.dailyLossInLbs !== null && (
              <div className={classNames(styles.value, dailyLossData.className)}>
                {dailyLossData.value}
              </div>
            )}
            {reading.dailyGainInLbs === null && reading.dailyLossInLbs === null && (
              <div className={styles.value}>NPR</div>
            )}
          </div>
          <div className={styles.measurement}>
            <Tooltip title="Weekly">
              <img src={WeeklyIcon} alt="" />
            </Tooltip>
            {reading.weeklyGainInLbs > 0 && (
              <div className={classNames(styles.value, weeklyGainData.className)}>
                +{weeklyGainData.value}
              </div>
            )}
            {reading.weeklyLossInLbs !== null && (
              <div className={classNames(styles.value, weeklyLossData.className)}>
                {weeklyLossData.value}
              </div>
            )}
            {reading.weeklyGainInLbs === null && reading.weeklyLossInLbs === null && (
              <div className={styles.value}>NPR</div>
            )}
          </div>
        </div>
        <div className={styles.date} title={receivedBySystem}>
          {measurementDate}
        </div>
      </div>
    </div>
  );
};

export default WeightScale;
