import { ReactElement, useState } from 'react';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import Button from '~/ui/components/common/Button';

import { NoteTemplateType } from '~/services/api/enums';
import UseNoteTemplateModal from './UseNoteTemplateModal';

interface IProps {
  typeId: NoteTemplateType;
  onNoteTemplateSelected: (content: string) => void;
}

const UseNoteTemplateButton = ({ typeId, onNoteTemplateSelected }: IProps): ReactElement => {
  const [isUseNoteTemplateModalOpen, setIsUseNoteTemplateModalOpen] = useState(false);

  const onUseTemplate = () => {
    setIsUseNoteTemplateModalOpen(true);
  };

  const onCloseUseNoteTemplateModal = () => {
    setIsUseNoteTemplateModalOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        fullWidth
        startIcon={<AssignmentOutlinedIcon />}
        onClick={onUseTemplate}
      >
        Use Template
      </Button>
      {isUseNoteTemplateModalOpen && (
        <UseNoteTemplateModal
          typeId={typeId}
          onNoteTemplateSelected={onNoteTemplateSelected}
          onClose={onCloseUseNoteTemplateModal}
        />
      )}
    </>
  );
};

export default UseNoteTemplateButton;
