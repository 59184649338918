import { DeviceType } from '~/services/api/enums';
import { DayPart, Day } from '~/types';

export enum DayColor {
  Monday = '#FF2A68',
  Tuesday = '#5857D6',
  Wednesday = '#0AD319',
  Thursday = '#FF1301',
  Friday = '#2B2B2B',
  Saturday = '#8E8E93',
  Sunday = '#5857D6',
}

export enum ChartColor {
  Blue = '#1E89C2',
  Green = '#33AD90',
  Red = '#F45760',
  Yellow = '#FFCE70',
}

export const labels = {
  systolic: 'Systolic',
  diastolic: 'Diastolic',
  heartRate: 'Heart Rate',
  weight: 'Weight',
  previousGain: 'Previous Gain',
  previousLoss: 'Previous Loss',
  dailyGain: 'Daily Gain',
  dailyLoss: 'Daily Loss',
  weeklyGain: 'Weekly Gain',
  weeklyLoss: 'Weekly Loss',
  spo2: 'SPO2',
  bloodGlucose: 'Blood Glucose',
};

export const categories = {
  [DeviceType.BloodPressure]: [labels.systolic, labels.diastolic, labels.heartRate],
  [DeviceType.WeightScale]: [
    labels.weight,
    labels.previousGain,
    labels.previousLoss,
    labels.dailyGain,
    labels.dailyLoss,
    labels.weeklyGain,
    labels.weeklyLoss,
  ],
  [DeviceType.PulseOximeter]: [labels.spo2, labels.heartRate],
  [DeviceType.BloodGlucose]: [labels.bloodGlucose],
};

export const analysisCategories = {
  [DeviceType.BloodPressure]: [labels.systolic, labels.diastolic, labels.heartRate],
  [DeviceType.WeightScale]: [labels.weight],
  [DeviceType.PulseOximeter]: [labels.spo2, labels.heartRate],
  [DeviceType.BloodGlucose]: [labels.bloodGlucose],
};

export interface IDayPartReading {
  day: Day;
  dayPart: DayPart;
  batteryLevel: number;
  signalLevel: number;
  values: number[];
  additionalValues: number[];
}

export const daysList = [
  Day.Monday,
  Day.Tuesday,
  Day.Wednesday,
  Day.Thursday,
  Day.Friday,
  Day.Saturday,
  Day.Sunday,
];

export const dayPartsList = [DayPart.Morning, DayPart.Noon, DayPart.Evening, DayPart.Night];

export interface IDay {
  day: Day;
  values: number[];
  value: string;
}

export interface IDayPart {
  dayPart: DayPart;
  days: IDay[];
}

export interface IAverageData {
  dayParts: IDayPart[];
}

export default {};
