import axios from 'axios';
import { IResponse } from '~/services/api/types';
import { ICarePlanIntervention } from './types';

const apiUrl = 'care-plan-interventions';

export const getList = (patientId: number): IResponse<ICarePlanIntervention[]> =>
  axios({
    method: 'get',
    url: `${apiUrl}/by-patient-id/${patientId}`,
  });

export const create = (
  patientId: number,
  data: ICarePlanIntervention,
): IResponse<ICarePlanIntervention> =>
  axios({
    method: 'post',
    url: apiUrl,
    data,
    params: {
      patientId,
    },
  });

export const update = (data: ICarePlanIntervention): IResponse<ICarePlanIntervention> =>
  axios({
    method: 'put',
    url: apiUrl,
    data,
  });

export const remove = (id: number): IResponse<void> =>
  axios({
    method: 'delete',
    url: `${apiUrl}/${id}`,
  });
