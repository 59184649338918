import initStore from './initStore';
import actions from './actions';
import thunks from './thunks';

import { ICarePlanInterventionsModel } from './types';

const carePlanInterventions: ICarePlanInterventionsModel = {
  ...initStore,
  ...actions,
  ...thunks,
};

export default carePlanInterventions;
