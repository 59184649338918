import { object, string } from 'yup';
import { phoneNumber } from '~/ui/constants/validation';

const physicianValidationSchema = object().shape({
  name: string().max(100, 'Max length - 100 symbols').required('Field is required'),
  phone: phoneNumber.required('Field is required'),
  fax: phoneNumber,
  specialty: string().max(100, 'Max length - 100 symbols').required('Field is required'),
});

export default physicianValidationSchema;
