import { ReactElement } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import MUITable from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Icon } from '@mui/material';
import { isoToFormat } from '~/utils/date';
import NoDataFound from '~/ui/components/reusable/NoDataFound';
import { IPatientInfo } from '~/services/api/patients/types';
import styles from './HistoryTable.module.scss';
import { IPatientDeviceHistoryLog } from '~/services/api/deviceHistoryLogs/types';
import { EVENT_ICONS } from '~/ui/pages/Inventory/popups/HistoryModal/constants';

interface IProps {
  patient: IPatientInfo;
  deviceHistoryLogs: IPatientDeviceHistoryLog[];
}

const HistoryTable = ({ patient, deviceHistoryLogs }: IProps): ReactElement => (
  <div className={styles.container}>
    <h4 className={styles.tableTopHeader}>History</h4>
    <div className={styles.tableContainer}>
      <TableContainer>
        <MUITable>
          <TableHead>
            <TableRow>
              <TableCell>Device Type</TableCell>
              <TableCell>Date and Time</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Event</TableCell>
              <TableCell>IMEI</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {deviceHistoryLogs.length ? (
              deviceHistoryLogs.map(deviceHistoryLog => (
                <TableRow key={deviceHistoryLog.id}>
                  <TableCell>{deviceHistoryLog.deviceTypeName}</TableCell>
                  <TableCell>
                    {isoToFormat(deviceHistoryLog.createdAt, 'dd MMM y H:mm', patient.timezone)}
                  </TableCell>
                  <TableCell className={styles.cell}>{deviceHistoryLog.userFullName}</TableCell>
                  <TableCell>
                    <div className={styles.event}>
                      <Icon color="primary">{EVENT_ICONS[deviceHistoryLog.eventId]}</Icon>
                      <span className={styles.eventName}>{deviceHistoryLog.eventName}</span>
                    </div>
                  </TableCell>
                  <TableCell>{deviceHistoryLog.deviceImei}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5}>
                  <NoDataFound />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </MUITable>
      </TableContainer>
    </div>
  </div>
);

export default HistoryTable;
