import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkUpdateCarePlanIntervention } from '../types';

const onUpdateCarePlanIntervention: IThunkUpdateCarePlanIntervention = thunk(
  // @ts-ignore
  async ({ updateCarePlanIntervention }, payload) => {
    const { data } = await api.carePlanInterventions.update(payload);
    updateCarePlanIntervention(data);
  },
);

export default onUpdateCarePlanIntervention;
