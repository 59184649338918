import { NoteTemplateType } from '~/services/api/enums';
import { INoteTemplatesStore } from './types';

const initStore: INoteTemplatesStore = {
  items: {
    [NoteTemplateType.Notes]: [],
    [NoteTemplateType.Goals]: [],
    [NoteTemplateType.Interventions]: [],
  },
};

export default initStore;
