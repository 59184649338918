import { ReactElement } from 'react';
import { Grid } from '@material-ui/core';
import { HowToReg, PersonRemove } from '@mui/icons-material';
import { format } from 'date-fns';
import { IPatientOverview } from '~/services/api/patients/types';
import SubHeaderItem from './SubHeaderItem';
import HeartRateIcon from './HeartRate.svg';

import styles from './SubHeader.module.scss';
import Button from '~/ui/components/common/Button';

interface IProps {
  patientOverview: IPatientOverview;
  onCustomizeDashboard: () => void;
}

const SubHeader = ({ patientOverview, onCustomizeDashboard }: IProps): ReactElement =>
  patientOverview ? (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <SubHeaderItem
              icon={<HowToReg className={styles.green} />}
              text={`Enrolled: ${format(new Date(patientOverview.createdAt), 'dd MMM y')}`}
            />
          </Grid>
          {patientOverview.dischargedAt !== null && (
            <Grid item>
              <SubHeaderItem
                icon={<PersonRemove className={styles.red} />}
                text={`Discharged: ${format(new Date(patientOverview.dischargedAt), 'dd MMM y')}`}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={2} alignItems="center">
          {patientOverview.irregularHeartbeatsCount != null && (
            <Grid item>
              <SubHeaderItem
                icon={<img src={HeartRateIcon} alt="" />}
                text={`Irregular Heartbeat: ${patientOverview.irregularHeartbeatsCount}`}
              />
            </Grid>
          )}
          <Grid item>
            <Button variant="contained" color="primary" onClick={onCustomizeDashboard}>
              Customize Dashboard
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <></>
  );

export default SubHeader;
