import { ReactElement } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import Analysis from './Analysis';
import MaxMinReading from './MaxMinReading';

import { IReading } from '~/services/api/readings/types';
import styles from './WeeklyAnalysis.module.scss';
import { DeviceType } from '~/services/api/enums';
import { getAverageData, getDayPartReadings } from '../helper';

interface IProps {
  readings: IReading[];
  deviceTypeId: DeviceType;
}

const WeeklyAnalysis = ({ readings, deviceTypeId }: IProps): ReactElement => {
  const dayPartReadings = getDayPartReadings(readings, deviceTypeId);

  const averageData = getAverageData(dayPartReadings);

  return (
    <Paper elevation={24} square className={styles.container}>
      <div className={styles.title}>Weekly Analysis</div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Analysis averageData={averageData} />
        </Grid>
        <Grid item xs={12}>
          <MaxMinReading dayPartReadings={dayPartReadings} deviceTypeId={deviceTypeId} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default WeeklyAnalysis;
