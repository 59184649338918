import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkCreateCarePlanIntervention } from '../types';

const onCreateCarePlanIntervention: IThunkCreateCarePlanIntervention = thunk(
  // @ts-ignore
  async ({ addCarePlanIntervention }, payload) => {
    const { patientId, data } = payload;
    const intervention = await api.carePlanInterventions
      .create(patientId, data)
      .then(res => res.data);
    addCarePlanIntervention(intervention);
  },
);

export default onCreateCarePlanIntervention;
