import { ReactElement, useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import Button from '~/ui/components/common/Button';

import { NoteTemplateType } from '~/services/api/enums';
import CreateNoteTemplateModal from './CreateNoteTemplateModal';

interface IProps {
  typeId: NoteTemplateType;
  content: string;
}

const CreateNoteTemplateButton = ({ typeId, content }: IProps): ReactElement => {
  const [isCreateNoteTemplateModalOpen, setIsCreateNoteTemplateModalOpen] = useState(false);

  const onSaveAsTemplate = () => {
    setIsCreateNoteTemplateModalOpen(true);
  };

  const onCloseCreateNoteTemplateModal = () => {
    setIsCreateNoteTemplateModalOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<SaveIcon />}
        onClick={onSaveAsTemplate}
      >
        Save as Template
      </Button>
      {isCreateNoteTemplateModalOpen && (
        <CreateNoteTemplateModal
          typeId={typeId}
          content={content}
          onClose={onCloseCreateNoteTemplateModal}
        />
      )}
    </>
  );
};

export default CreateNoteTemplateButton;
