import initStore from './initStore';
import actions from './actions';
import thunks from './thunks';

import { IReadingsModel } from './types';

const readings: IReadingsModel = {
  ...initStore,
  ...actions,
  ...thunks,
};

export default readings;
