import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import validate from './form/validate';
import Button from '~/ui/components/common/Button';
import Textarea from '~/ui/components/inputs/Textarea';
import Checkbox from '~/ui/components/inputs/Checkbox';
import DatePicker from '~/ui/components/inputs/DatePicker';

import { IFormValues } from './types';
import styles from './NoteForm.module.scss';
import CreateNoteTemplateButton from '../../CreateNoteTemplateButton';
import { NoteTemplateType } from '~/services/api/enums';
import UseNoteTemplateButton from '../../UseNoteTemplateButton';

interface IProps {
  mode?: 'create' | 'edit';
  showBackBtn?: boolean;
  initialValues: IFormValues;
  isProcessing: boolean;
  onSubmit: (data: IFormValues, reset: any) => void;
  onClose?: () => void;
}

const NoteForm = ({
  mode = 'create',
  showBackBtn = false,
  initialValues,
  isProcessing,
  onSubmit,
  onClose,
}: IProps): ReactElement => {
  const {
    register,
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: validate,
    defaultValues: initialValues,
  });

  const showReminder = watch('showReminder');

  const onNoteTemplateSelected = (content: string) => {
    setValue('content', content, { shouldValidate: true });
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={4}>
              <Checkbox
                name="showReminder"
                control={control}
                errors={errors}
                label="Reminder to follow up"
              />
            </Grid>
            <Grid item xs={4}>
              {showReminder && (
                <DatePicker
                  name="reminderDate"
                  minDate={new Date()}
                  control={control}
                  errors={errors}
                  label="Date *"
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={4}>
              <Checkbox
                name="isTelephoneConversation"
                control={control}
                errors={errors}
                label="Telephone Conversation"
              />
            </Grid>
            <Grid item xs={4}>
              <Checkbox
                name="notifyHealthManager"
                control={control}
                errors={errors}
                label="Notify Health Manager"
              />
            </Grid>
            <Grid item xs={4}>
              <UseNoteTemplateButton
                typeId={NoteTemplateType.Notes}
                onNoteTemplateSelected={onNoteTemplateSelected}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className={styles.heightSpacer} />
      <Textarea name="content" register={register} errors={errors} label="Note" />
      <div className={styles.btnWrapper}>
        {showBackBtn && (
          <>
            <Button
              variant="outlined"
              color="default"
              startIcon={<ArrowBackIosIcon />}
              onClick={onClose}
            >
              Back
            </Button>
            <div className={styles.spacer} />
          </>
        )}
        <CreateNoteTemplateButton typeId={NoteTemplateType.Notes} content={watch().content} />
        <div className={styles.spacer} />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          isLoading={isProcessing}
          disabled={isProcessing}
        >
          {mode === 'create' ? 'Create' : 'Save'}
        </Button>
      </div>
    </form>
  );
};

export default NoteForm;
