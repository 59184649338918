import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkRemoveCarePlanIntervention } from '../types';

const onRemoveCarePlanIntervention: IThunkRemoveCarePlanIntervention = thunk(
  // @ts-ignore
  async ({ removeCarePlanIntervention }, id) => {
    await api.carePlanInterventions.remove(id);
    removeCarePlanIntervention(id);
  },
);

export default onRemoveCarePlanIntervention;
