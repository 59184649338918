import { useState, ReactElement, useEffect, useMemo } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import Modal from '~/ui/components/common/Modal';
import { NoteTemplateType } from '~/services/api/enums';
import Button from '~/ui/components/common/Button';
import { IFormValues } from './types';
import initialValues from './form/initialValues';
import Loader from '~/ui/components/common/Loader';
import Input from '~/ui/components/inputs/Input';
import { INoteTemplate } from '~/services/api/noteTemplates/types';
import styles from './UseNoteTemplateModal.module.scss';
import ConfirmModal from '~/ui/components/common/ConfirmModal';

interface IProps {
  typeId: NoteTemplateType;
  onNoteTemplateSelected: (content: string) => void;
  onClose: () => void;
}

const UseNoteTemplateModal = ({
  typeId,
  onNoteTemplateSelected,
  onClose,
}: IProps): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedNoteTemplate, setSelectedNoteTemplate] = useState<INoteTemplate>(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const initialNoteTemplates = useStoreState(state => state.noteTemplates.items[typeId]);

  const { showError } = useStoreActions(actions => actions.snackbar);
  const { onGetNoteTemplatesByTypeId, onRemoveNoteTemplate } = useStoreActions(
    actions => actions.noteTemplates,
  );

  const {
    register,
    watch,
    formState: { errors },
  } = useForm<IFormValues>({
    defaultValues: initialValues,
  });

  const name = watch('name').toLowerCase();

  const noteTemplates = useMemo(
    () => initialNoteTemplates.filter(x => x.name.toLowerCase().includes(name)),
    [initialNoteTemplates, name],
  );

  const onMount = async () => {
    try {
      await onGetNoteTemplatesByTypeId(typeId);

      setIsLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  const onNoteTemplateClick = (noteTemplate: INoteTemplate) => {
    setSelectedNoteTemplate(noteTemplate);
  };

  const onApply = () => {
    onNoteTemplateSelected(selectedNoteTemplate.content);
    onClose();
  };

  const onRemove = () => {
    setShowConfirmModal(true);
  };

  const handleDelete = async () => {
    try {
      await onRemoveNoteTemplate(selectedNoteTemplate.id);

      setSelectedNoteTemplate(null);
      setShowConfirmModal(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  return (
    <Modal open closeOnlyByCross fullWidth title="Your Templates" maxWidth="sm" onClose={onClose}>
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Input name="name" register={register} errors={errors} label="Search" />
              </Grid>
              <Grid item xs={12}>
                <Grid container className={styles.noteTemplatesContainer} alignContent="flex-start">
                  {noteTemplates.map(noteTemplate => (
                    <Grid
                      item
                      key={noteTemplate.id}
                      xs={12}
                      onClick={() => onNoteTemplateClick(noteTemplate)}
                    >
                      <Grid
                        container
                        className={classNames(styles.noteTemplateContainer, {
                          [styles.isSelected]: selectedNoteTemplate?.id === noteTemplate.id,
                        })}
                        alignItems="center"
                        justifyContent="space-between"
                        wrap="nowrap"
                      >
                        <Grid item className={styles.name}>
                          {noteTemplate.name}
                        </Grid>
                        <Grid item className={styles.remove}>
                          <IconButton title="Remove" onClick={onRemove}>
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button size="large" variant="outlined" color="primary" onClick={onClose}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      disabled={selectedNoteTemplate === null}
                      onClick={onApply}
                    >
                      Apply
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
        {showConfirmModal && (
          <ConfirmModal
            title="Remove"
            description="Are you sure you want to remove current template?"
            onClose={() => setShowConfirmModal(false)}
            onConfirm={handleDelete}
          />
        )}
      </>
    </Modal>
  );
};

export default UseNoteTemplateModal;
