import { action } from 'easy-peasy';
import { IActionRemoveNoteTemplate } from '../types';

const removeNoteTemplate: IActionRemoveNoteTemplate = action((state, id) => {
  state.items = Object.entries(state.items).reduce(
    (result, [key, items]) => ({ ...result, [key]: items.filter(item => item.id !== id) }),
    {} as typeof state.items,
  );
});

export default removeNoteTemplate;
